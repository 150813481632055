import { HardDriveUpload, X } from "lucide-react";
import { ReactNode, useCallback } from "react";
import Dropzone from "react-dropzone";
import { isEmpty } from "sutro-common";
import { DataUrlImage } from "sutro-common/sutro-data-store-types";

import { Instructions } from "~/components/app/instructions";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";

import { Label } from "../../ui/label";

interface ImageUploadProps {
  /** You can add a label to this, but if you're using it in a form, you should use the label component there instead of here */
  label?: string;
  /** If there's a currently uploaded image, then we should show it */
  currentImage?: DataUrlImage;
  /** Any notes you want shown below the input go here */
  notes?: ReactNode;
  /** If the image is a "logo" or "avatar" or similar, you can set it here. Otherwise, it will just say "image" */
  imageDesignation?: string;
  /** What to do when an image is uploaded */
  onImageUpload: (image?: DataUrlImage) => void;
  /** Represents the error message */
  error?: string;
  /** Allowed file extensions, e.g. ["png", "jpg", "jpeg"] */
  allowedExtensions?: string[];
}

/**
 * This component is for uploading relatively small images (like logos).
 *
 * It takes them and converts them to a data url. For larger images, we should
 * use a new (as yet uncreated) component that uploads the image to a server
 */
export function ImageUpload({
  onImageUpload,
  currentImage,
  label,
  notes,
  imageDesignation,
  error,
  allowedExtensions,
}: ImageUploadProps) {
  const onDropFiles = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        return;
      }

      const newImage = new Image();
      newImage.onload = function () {
        const width = newImage.width;
        const height = newImage.height;

        const reader = new FileReader();
        reader.onload = function () {
          onImageUpload({ image: reader.result as string, width, height });
        };
        reader.readAsDataURL(acceptedFiles[0]);
      };
      newImage.src = URL.createObjectURL(acceptedFiles[0]);
    },
    [onImageUpload]
  );

  const clearImage = useCallback(() => {
    onImageUpload();
  }, [onImageUpload]);

  const acceptedFileTypes = allowedExtensions
    ? allowedExtensions.reduce(
        (acc, ext) => {
          acc[`image/${ext}`] = [];
          return acc;
        },
        {} as Record<string, string[]>
      )
    : { "image/*": [] };

  return (
    <Label className="gap-2 text-lg">
      {label && <span>{label}</span>}

      <div className="relative">
        {isEmpty(currentImage?.image) ? null : (
          <Button
            variant="outline"
            size="icon"
            className="absolute right-0 m-2 size-6"
            onClick={clearImage}
          >
            <X className="size-4" />
          </Button>
        )}
        <Dropzone onDrop={onDropFiles} accept={acceptedFileTypes} maxFiles={1}>
          {({ getRootProps, getInputProps, isDragAccept }) => (
            <section>
              <div
                {...getRootProps()}
                className={cn(
                  "border-border flex h-32 w-full cursor-pointer flex-col items-center justify-center rounded-md border p-2",
                  {
                    "bg-blue-100": isDragAccept,
                    "border-red-600": error,
                  }
                )}
              >
                <input {...getInputProps()} />
                {currentImage?.image ? (
                  <img
                    alt="Logo"
                    className="aspect-auto max-h-12"
                    src={currentImage.image}
                  />
                ) : (
                  <div className="flex items-center justify-center">
                    <HardDriveUpload className="mr-2 w-5" />
                    <span className="text-sm font-normal">
                      Drag and drop your {imageDesignation ?? "image"} or{" "}
                      <span className="text-primary">click here</span>
                    </span>
                  </div>
                )}
              </div>
              {error && (
                <div className="mt-2 flex items-center">
                  <span className="text-sm font-medium text-red-600">
                    {error}
                  </span>
                </div>
              )}
              {notes && <Instructions className="mt-2">{notes}</Instructions>}
            </section>
          )}
        </Dropzone>
      </div>
    </Label>
  );
}
