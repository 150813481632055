import { PropsWithChildren } from "react";

import { cn } from "~/lib/utils";

export function Instructions({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <p className={cn("text-sm font-normal text-muted-foreground", className)}>
      {children}
    </p>
  );
}
